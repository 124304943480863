import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ImageUrl } from '../../../utils/constants';

const LazyImage = (props) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const imageRef = useRef(null);

  const { src, style } = props;

  useEffect(() => {
    const observer = new window.IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        setIsIntersecting(true);
        if(imageRef.current){
          observer.unobserve(imageRef.current);
        }
      }
    });

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, []);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  const fallBack = (e) => {
    e.target.src = props.fallbackImage ? props.fallbackImage : ImageUrl + '/images/placeholder/video-placeholder.jpg'
  }

  return (
    <div ref={imageRef} className={props.div}>
      {isIntersecting && (
        <img
          {...props}
          src={src}
          onLoad={handleImageLoad}
          style={{ ...style, visibility: isLoaded ? 'visible' : 'hidden'}}
          loading='lazy'
          onError={props.fallBack || fallBack}
        />
      )}
    </div>
  );
};

LazyImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  div: PropTypes.string,
  style:PropTypes.object,
  fallBack: PropTypes.func,
  fallbackImage: PropTypes.string,
};

export default LazyImage;
